import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import './DashboardBox.css'
import GridBox from './GridBox'
import {PiAlarmBold,PiNotepadFill} from 'react-icons/pi'
import {BiBookmarkAltMinus} from 'react-icons/bi'
// import {IoCalendarClearOutline} from 'react-icons/io5'
import {CgCalendar} from 'react-icons/cg'
// import { get_dashboard_data } from '../../helper/Api'
import {AddCommasToAmount} from '../../helper/Utils'
import InterestFromBankBox from './InterestFromBankBox'


const DashboardBox = ({data, showPopupFun, listReload}) => {
  const navigate = useNavigate();
  const navigateToScheme = (url) => {
    navigate(url);
  };

  return (
    <div className="dashboardBox">
        <GridBox caption="" onClick={() => navigateToScheme('/budgets')} icon={<PiAlarmBold/>} title={"Provisioned Budget"} amount={AddCommasToAmount(data.total_provisional_budget)} />
        <GridBox caption="" onClick={() => navigateToScheme('/budgets')} icon={<PiAlarmBold/>} title={"Released Budget"} amount={AddCommasToAmount(data.total_budget.toFixed(2))} />
        <GridBox caption="" onClick={() => navigateToScheme('/reports/Utilized Budget')} icon={<PiNotepadFill/>} title={"Utilized Budget"} amount={AddCommasToAmount(data.utilised_budget)} box_bg={'#DAFBEE'} icon_bg="#5CBA96"/>
        <GridBox caption="" onClick={() => navigateToScheme('/fund-invoice-list?active=invoice-status')} icon={<BiBookmarkAltMinus/>} title={"Pending for Approval"}  amount={AddCommasToAmount(data.sanctioned_amount)} box_bg={'#FFE7E5'} icon_bg="#EC8D87" subtitle={"No Of Invoices"} subtitleValue={data.pending_invoices_count}/>
        <GridBox caption="" onClick={() => navigateToScheme('/fund-invoice-list?active=payment-status')} icon={<BiBookmarkAltMinus/>} title={"Pending for Payments"} amount={AddCommasToAmount(data.payable_expanses)} box_bg={'#FFE7E5'} icon_bg="#EC8D87" subtitle={"No Of Invoices"} subtitleValue={data.payable_expenses_invoice_count}/>
        <GridBox caption="" onClick={() => navigateToScheme('/schemes')} icon={<CgCalendar/>} title={"Balance Budget"} amount={AddCommasToAmount(data.balance)} box_bg={'#FFF4DE'} icon_bg="#ECBA5D"/>
        <GridBox caption="" onClick={() => navigateToScheme('/reports/Accumulated Deduction')} icon={<CgCalendar/>} title={"Accumulated Deductions"} amount={AddCommasToAmount(data?.accumulated || 0)} box_bg={'#FFF4DE'} icon_bg="#ECBA5D"/>
        <InterestFromBankBox handleEditAccount={showPopupFun} reloadList={listReload}/>
    </div>
  )
}

export default DashboardBox
